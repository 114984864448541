@import "../../assets/styles/colors.css";
@import "../../assets/styles/typography.css";

.trial-page-container {
  height: 100%;
  max-width: 100vw;
  min-height: 100vh;
  min-width: 100vw;
}

.trial-user-survey-page {
  padding-bottom: 8rem;
}

.trial-page-container *:not[svg],
.trial-page-container *:not[path],
.trial-page-container *:not[a],
.trial-page-container *:not[button] {
  color: #000;
}

.center-page {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.gray-bg {
  background: var(--grayBG);
}

.trial-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.MuiCardContent-root,
.MuiCardContent-root:last-child {
  padding: 0;
}

.card-lg {
  padding: 5rem;
  width: 50rem;
  height: 40rem;
  border-radius: 1.2rem;
  box-sizing: border-box;
}

.card-lg-mobile {
  padding: 5rem;
  width: calc(100% - 15vw);
  height: calc(100% - 22vh);
  border-radius: 1.2rem;
  box-sizing: border-box;
}

.card-sm {
  padding: 6rem;
  padding-bottom: 4rem;
  width: 60rem;
  border-radius: 1.2rem;
  box-sizing: border-box;
}

.error {
  color: var(--errorPink);
}
.success {
  color: var(--textGreenDark);
}

.trial-page-container .survey-container .trial-text-field {
  border: 1px solid rgb(229, 235, 236);
}
.trial-page-container .survey-container .MuiSelect-select.MuiSelect-outlined {
  border: 1px solid rgb(229, 235, 236);
}
.trial-page-container .survey-container .MuiInputBase-root {
  border: unset;
}

.trial-text-field input {
  padding: 0;
  font-size: 1.3rem;
  line-height: 2rem;
  font-family: "OpenSansSemiBold";
}

.context-description {
  padding: 0rem 0.5rem !important;
}

.trial-hidden {
  display: none;
}

.trial-text-field.lg input {
  padding: 0;
  font-size: 2.4rem;
  line-height: 2.9rem;
  font-family: "OpenSansSemiBold";
}

a {
  color: var(--textGreenDark);
  text-transform: none;
}

.page-form-header {
  text-align: center;
  margin-top: 3.5rem;
  margin-bottom: 3rem;
}

.page-key-logo {
  width: 15rem;
  height: 8.5rem;
}

.MuiButton-containedPrimary {
  color: #fff;
}

.MuiRadio-colorPrimary .MuiSvgIcon-root {
  color: #c8c8c8;
  width: 1.8rem;
  height: 1.8rem;
}

.MuiRadio-colorPrimary.Mui-checked
  .MuiSvgIcon-root[data-testid="RadioButtonUncheckedIcon"] {
  color: var(--appGreen);
  stroke: var(--appGreen);
  stroke-width: 4px;
  width: 1.6rem;
  height: 1.6rem;
  padding: 0 1px;
}

.MuiRadio-colorPrimary.Mui-checked
  .MuiSvgIcon-root[data-testid="RadioButtonCheckedIcon"] {
  color: transparent;
  display: none;
}

.contact-us-card {
  height: 73rem;
}

.message-box {
  height: 15rem;
}

.request-demo-card {
  height: 35rem;
}

.loader-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background: rgba(0, 0, 0, 0.834);
  z-index: 1;
}

.spinner {
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: #11781b transparent #11781b transparent;
  border-radius: 50%;
  animation: spin-anim 1.2s linear infinite;
}

@keyframes spin-anim {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.email-field {
  cursor: pointer;
}

.footer {
  position: fixed;
  bottom: 0;
  background: white;
  width: 100%;
}

.footer a {
  color: var(--textBlack);
  text-decoration: none;
}

.footer .footer-divider {
  margin: 0 2rem;
}

.cookies-popup {
  width: 97%;
}

.cookies-popup .MuiSnackbarContent-root {
  width: 100%;
}

.cookies-popup a {
  color: #fff;
}

.cookies-popup-container {
  display: flex;
  justify-content: space-between;
  width: 98%;
  color: #fff;
}

.cookies-popup .MuiSvgIcon-root {
  color: #fff;
}

.trial-info-page {
  font-family: 'henderson-bcg-serif,"Palatino Linotype",Palatino,Garamond,Georgia,serif';
}

.text-panel {
  margin-bottom: 2.5rem;
  font-size: 1.8rem;
  line-height: 2rem;
}

.rte-h3 {
  font-weight: 700;
  color: #197a56;
  text-transform: uppercase;
  font-size: 1.6rem;
  line-height: 1.5rem;
  margin-bottom: 2rem;
}

.data {
  font-size: 1.4rem;
}

.trial-page-container .MuiInputBase-root {
  border: 1px solid #d3d3d3;
  width: 100%;
}

.trial-text-field .MuiInputBase-inputMultiline {
  padding: 0;
  font-size: 1.3rem;
  line-height: 2rem;
  font-family: "OpenSansSemiBold";
}

.trial-page-container .MuiSelect-select.MuiSelect-outlined {
  font-size: 1.3rem;
  font-family: "OpenSansSemiBold";
  padding: 1.8rem;
  border: 1px solid black;
  border-radius: 6px;
}

.trial-page-container .MuiSelect-nativeInput {
  height: 5.6rem;
}

.trial-page-container .MuiMenu-paper {
  width: 80%;
}

.trial-page-container .MuiMenu-list {
  width: 100%;
}

.trial-page-container .MuiMenuItem-root {
  font-size: 1.3rem;
  font-family: "OpenSans";
}

.trial-page-container .select-placeholder {
  color: lightgray;
}

.trial-page-container.mobile {
  padding-bottom: 1rem;
}

.trial-page-container.mobile .page-form-header {
  margin-top: 1.5rem;
  margin-bottom: 2rem;
}

.trial-page-container.mobile .page-key-logo {
  width: 10rem;
  height: 6rem;
}

.card-xl-desktop {
  width: 82rem;
  border-radius: 1.2rem;
}

.card-xl-desktop .survey-container {
  padding: 5rem 5rem 3rem 5rem;
}

.card-xl-mobile {
  width: 100%;
  border-radius: 1.2rem;
  margin-bottom: 0;
  height: calc(100vh - 11.5rem);
}

.card-xl-tablet {
  width: calc(100% - 10vh);
  border-radius: 1.2rem;
}

.card-xl-mobile .survey-container,
.card-xl-tablet .survey-container {
  padding: 2rem;
  height: calc(100vh - 23rem);
  overflow: auto;
}

.card-xl-mobile .survey-container::-webkit-scrollbar,
.card-xl-tablet .survey-container::-webkit-scrollbar {
  width: 0;
}

.card-xl-mobile .MuiTextField-root,
.card-xl-tablet .MuiTextField-root {
  padding: 1.8rem;
}

.card-xl-mobile .MuiGrid-item {
  padding-top: 3rem;
}

.card-xl-mobile .MuiGrid-item {
  padding-top: 1rem;
}

.button-mobile {
  padding: 4rem;
}

.request-trial-card {
  padding: 1rem;
  margin-bottom: 10rem;
  border: 1px solid #fafafa;
  border-radius: 25px;
  width: 58rem;
}

@media (max-width: 768px) {
  .request-trial-card {
    width: 100%;
  }
}

.request-trial-text-field {
  padding: 0;
}

.request-trial-text-field .MuiOutlinedInput-input {
  color: #a6a6a6;
  font-size: 1.8rem;
}
.request-trial-accept-terms {
  color: #a6a6a6;
  font-size: 1.6rem;
  font-family: "OpenSans";
}

.trial-button {
  height: 4rem;
  min-width: 12rem;
  font-size: 1.8rem;
  border-radius: 0.6rem;
  padding: 1.35rem;
}

.trial-button.secondary {
  color: #a6a6a6;
  border-color: #a6a6a6;
  border-style: solid;
  border-width: 1px;
  background-color: transparent;
}

.trial-button.secondary:hover {
  background: #f2f2f2;
}
.current-situation-item-desktop {
  font-size: 1.4rem;
  font-family: "OpenSans";
  padding: 1rem;
}

.current-situation-item-mobile,
.current-situation-item-tablet {
  font-family: "OpenSans";
}
