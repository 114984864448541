@import "./colors.css";

@font-face {
  font-family: 'OpenSansLight';
  font-style: normal;
  font-weight: 400;
  src: local('OpenSansLight'), local('OpenSans-Light'), url('../fonts/opensans-light-webfont.woff2') format('woff2');
}

@font-face {
  font-family: 'OpenSansLight2';
  font-style: normal;
  font-weight: 500;
  src: local('OpenSansLight2'), local('OpenSans-Light'), url('../fonts/opensans-light-webfont.woff2') format('woff2');
}

@font-face {
  font-family: 'OpenSans';
  font-style: normal;
  font-weight: 600;
  src: local('OpenSans'), local('OpenSans-Regular'), url('../fonts/opensans-regular-webfont.woff2') format('woff2');
}

@font-face {
  font-family: 'OpenSansSemiBold';
  font-style: normal;
  font-weight: 600;
  src: local('OpenSansSemiBold'), local('OpenSans-SemiBold'), url('../fonts/opensans-semibold-webfont.woff2') format('woff2');
}

@font-face {
  font-family: 'OpenSansBold';
  font-style: normal;
  font-weight: 700;
  src: local('OpenSansBold'), local('OpenSans-SemiBold'), url('../fonts/opensans-bold-webfont.woff2') format('woff2');
}

.MuiTypography-root {
  font-size: 1.6rem;
  font-family: "OpenSansSemiBold";
}

.MuiTypography-h3 {
  font-size: 2.4rem;
  line-height: 2.4rem;
  font-family: "OpenSansBold";
}

.MuiTypography-body2 {
  font-size: 1.6rem;
  line-height: 2rem;
  font-weight: 600;
  font-family: "OpenSans";
}

.MuiTypography-subtitle1 {
  font-size: 1.3rem;
  line-height: 1.8rem;
  font-weight: 600;
  font-family: "OpenSansLight";
}

.MuiTypography-caption {
  font-size: 1.3rem;
  line-height: 1.5rem;
  font-family: "OpenSans";
}

.light-header.MuiTypography-h3,
.light-header.MuiTypography-h4 {
  font-family: "OpenSans";
}
