.ag-theme-key-zbo {
  --ag-header-background-color: #3a404d;
  --ag-header-color: #fff;
  --ag-data-color: #3a404d;
  --ag-row-height: 40px;
  --ag-header-height: 47px;
  --ag-icon-font-family: agGridAlpine;
  --ag-secondary-foreground-color: #fff;
  --ag-header-column-separator-display: "block";
  --ag-header-column-separator-width: 1px;
  --ag-header-column-separator-color: hsla(204, 8%, 76%, 0.5);
  --ag-font-family: "OpenSansSemiBold";
  --ag-row-font-family: "OpenSans";
  --ag-font-size: 13px;
  --ag-background-color: #e5e5e5;
  --ag-odd-row-background-color: #f2f2f2;
  --ag-cell-horizontal-border: "solid red";
  --ag-cell-vertical-border: "solid red";
  --ag-border-radius: 3px;
  --ag-list-item-height: 20px;
  --ag-widget-vertical-spacing: 12px;
}

.ag-header {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.ag-root {
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

.ag-theme-material {
  --ag-material-primary-color: #35a761;
}

.ag-menu {
  padding: 0.4rem;
}

.ag-layout-auto-height .ag-center-cols-clipper {
  min-height: 20rem;
}

/* AG Grid Styles Start */
.ag-cell-wrapper.ag-row-group {
  align-items: center !important;
}
.ag-cell-wrapper {
  width: 100%;
}
.ag-theme-balham {
  color: darkgrey;
  font-size: 1.3rem;
}

.ag-theme-balham .ag-header {
  font-weight: normal;
  font-size: 1.3rem;
}

.ag-theme-balham .ag-cell {
  line-height: 1.7rem;
}

.ag-header {
  /* border: none; */
}

.ag-row .ag-cell {
  display: flex;
  align-items: center;
  border-right: 1px solid #bdc3c7;
}

.ag-theme-balham .ag-row-odd {
  background-color: whitesmoke;
}

.ag-theme-balham .ag-row {
  /* border: none; */
}

.ag-theme-balham .ag-paging-panel {
  height: 40px;
}

.ag-root-wrapper {
  border-radius: 4px;
  overflow: visible;
}

.ag-column-select-panel {
  overflow-y: visible;
  min-height: 22rem;
}

.ag-tooltip {
  background-color: white;
}

.ag-header-icon {
  display: inline-block;
}

.ag-icon.ag-icon-asc,
.ag-icon.ag-icon-desc,
.ag-icon.ag-icon-menu,
.ag-icon.ag-icon-filter,
.ag-icon.ag-icon-expanded,
.ag-icon.ag-icon-contracted {
  color: white;
}

.ag-row {
  font-family: var(--ag-row-font-family);
  color: var(--ag-data-color, var(--ag-foreground-color, #000));
  font-size: 1.3rem;
}
