@import "./assets/styles/colors.css";
@import "./assets/styles/typography.css";
@import "./assets/styles/common.css";
@import "./assets/styles/grid-theme.css";

/* PR comment: Let's not make the file too large. Hard to maintain.
  Keep component and page related css in their respective folders. Or use sx property - to keep the presentation layer in one file */
.row-controls {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.MuiButton-startIcon {
  margin-left: -0.8rem;
  margin-right: 0.4rem;
}

.MuiButton-contained .MuiSvgIcon-root {
  color: white;
}

.MuiSvgIcon-root {
  color: #9a9a9a;
}

.MuiCheckbox-root>.MuiSvgIcon-root {
  color: #3a404d;
}

.icon-regular {
  font-size: 16px;
}

.text-white {
  color: #fff;
}

.text-red {
  color: #ff5a67;
}

.text-green {
  color: #58a468;
}

.grid-container {
  padding: 2.5rem 3rem;
}

.header-logo {
  height: 6rem;
}

input {
  height: 2.8rem;
  line-height: 1.2rem;
  letter-spacing: 1px;
  width: 20rem;
  border-color: lightgray;
  border-width: 2px;
  border-radius: 4px;
  border-style: solid;
  padding-left: 7px;
}

input:focus-visible {
  outline: none;
}

.input-margin {
  margin-bottom: 1rem;
}

.align-center {
  display: flex;
  place-content: space-between;
  align-items: center;
}

.cursor {
  cursor: pointer;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(45, 67, 82, 0.5);
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar {
  height: 15px;
  width: 15px;
}

fieldset {
  display: none !important;
}

legend {
  display: none !important;
}

.MuiSelect-select {
  border-style: solid;
  border-color: darkgray;
  border-width: 1px;
  padding: 0.6rem;
  font-size: 11px;
  font-family: "OpenSansSemiBold";
}

.control {
  margin-bottom: 1.5rem;
}

.control.lessMargin {
  margin-bottom: 0.5rem;
}

.MuiAutocomplete-input {
  border-style: solid;
  border-color: darkgray;
  border-width: 1px;
  padding: 0.8rem;
  height: 1.8rem;
  min-height: 1.8rem;
}

.MuiInput-input {
  border-style: solid;
  border-color: darkgray;
  border-width: 1px;
  padding: 0.5rem;
  height: 1.8rem;
  min-height: 1.8rem;
  font-size: 11px;
  font-family: "OpenSansSemiBold";
}

.MuiInputBase-root::before {
  border: none !important;
}

.MuiInputBase-root::after {
  border: none !important;
}

.MuiOutlinedInput-root .MuiAutocomplete-input {
  padding: 0.6rem;
  height: 14px;
  min-height: 0;
}

.MuiAutocomplete-inputRoot {
  padding: 0 !important;
  font-family: "OpenSansSemiBold";
  font-size: 1.1rem;
}

.dialog-form {
  width: 45rem;
}

.small-dialog-form {
  width: 45rem;
  height: 14rem;
}

.label-text {
  font-family: "OpenSansSemiBold";
  font-size: larger;
  margin-bottom: 0.5rem;
}

.label-text>.MuiFormControlLabel-label {
  font-family: "OpenSansSemiBold";
  font-size: 1.2rem;
}

.form-sub-heading {
  text-decoration: underline;
  padding-bottom: 1rem;
}

.gap {
  display: flex;
  gap: 3rem;
}

.MuiFormHelperText-root {
  color: #3a404d;
  font-size: 1.3rem;
}

.Mui-error>.MuiSelect-select {
  border-style: solid;
  border-width: 1px;
  border-color: red;
}

.Mui-error>.MuiSelect-select {
  border-style: solid;
  border-width: 1px;
  border-color: red;
}

.Mui-error>.MuiInputBase-input {
  border-style: solid;
  border-width: 1px;
  border-color: red;
}

.trial-page-container .MuiInputBase-root.Mui-error {
  border-style: solid;
  border-width: 1px;
  border-color: red;
}

.request-trial-text-field>.Mui-error>.MuiInputBase-input {
  border: unset;
}

.trial-box>.Mui-error .MuiInputBase-root {
  border-style: solid;
  border-width: 1px;
  border-color: red;
}

.hidden {
  display: none;
}

.space-between-controls {
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
}

.shadded {
  background-color: gainsboro;
}

.upgrade-popup {
  width: 500px;
}

.upgrade-header {
  font-size: 1.5rem;
  font-weight: 500;
  color: gray;
  margin-bottom: 0.3rem;
}

.upgrade-content {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1.2rem;
}

.upgrade-popup-header {
  font-size: 1.6rem;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  margin-bottom: 2rem;
}

.close-icon {
  font-size: 2.5rem;
}

.version-dropdown {
  width: 40rem;
}

.confirm-popup {
  width: 500px;
  font-size: 1.5rem;
}

.progress-text {
  font-weight: 400;
  color: chocolate;
  text-decoration: underline;
  text-align: center;
}

.workspace-list {
  list-style-type: none;
  padding-inline-start: 1rem;
}

.MuiButtonBase-root {
  text-transform: unset;
}

.text {
  font-family: "OpenSans";
  font-size: 1.3rem;
  line-height: normal;
}

.hint-text {
  font-family: "OpenSans";
  font-size: 1.3rem;
  line-height: normal;
}

.delete-text {
  font-family: "OpenSans";
  font-size: 1rem;
  line-height: normal;
  color: black;
}

.delete-textbox {
  margin-top: 0.5rem;
  height: 2.1rem;
  line-height: 1.2rem;
  letter-spacing: 1px;
  width: 18rem;
  border-color: lightgray;
  border-width: 2px;
  border-radius: 4px;
  border-style: solid;
  padding-left: 7px;
}

.icon {
  color: darkslategrey;
}

.flexRow {
  display: inline-flex;
  justify-content: flex-start;
  gap: 5px;
  align-items: center;
  margin-top: 3px;
}

.flexRow.nearTop {
  margin-top: -10px;
}

.error>.MuiAlert-icon>.MuiSvgIcon-root {
  color: #e20909;
}

.success>.MuiAlert-icon>.MuiSvgIcon-root {
  color: #00a452;
}

.icon-link-container {
  display: inline-flex;
  gap: 3px;
  cursor: pointer;
}

.icon-link-container>.link {
  color: #00a452;
}

.show {
  display: inline-flex;
}

.hidden {
  display: none;
}

.full-page {
  height: calc(100vh - 5rem);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #3a404d;
  flex-direction: column;
}

.control-box {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  background: #fff;
  border-radius: 3px;
}

.padded {
  padding: 3rem 4rem;
}

.highlight {
  color: #fff;
  height: 5rem;

  background: #616670;
  border-radius: 0 0 3px 3px;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2.8rem;
  font-size: 12px;
}

.highlight>a {
  color: #fff;
  font-family: "OpenSansSemiBold";
}

.highlight-box {
  background: #fff;
}

.control-box-head {
  text-align: center;
}

.pagination-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.pagination-header {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 13px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.MuiLinearProgress-indeterminate {
  width: 100hw;
  position: fixed;
  top: 0;
}

.ag-pinned-right-cols-container .ag-cell {
  padding-left: 0px;
  padding-right: 0px;
}

.ag-paging-panel {
  color: rgb(0 0 0 / 87%);
}

.ag-horizontal-right-spacer {
  opacity: 0;
}

.ag-body-horizontal-scroll {
  border: #616670;
}

.next-button {
  font-size: 13px;
  width: 100%;
  padding: 15px;
  border-radius: 3px;
  background-color: #3a404d !important;
  border-color: #3a404d !important;
  margin-bottom: 0.8rem !important;
  font-family: "OpenSansSemiBOld";
  height: 50px;
  margin-top: 20px !important;
}

.dropDown-menu {
  display: flex !important;
  font-size: 1.4rem;
  font-weight: 600;
  justify-content: center !important;
  align-items: center !important;
  min-width: 15rem !important;
  cursor: pointer;
}

.MuiMenu-paper {
  font-size: 1.4rem;
  font-weight: 600;
}

.login-label-text {
  color: #5e5e5e !important;
  line-height: 1.8rem !important;
  font-family: "OpenSansBold" !important;
  font-size: 13px;
  margin-bottom: 0.8rem;
}

.login-header {
  font-family: "OpenSansBold" !important;
  font-size: 14px;
  margin: 0;
  color: #5e5e5e !important;
}

.login-control {
  margin-top: 1.5rem !important;
}

.login-controller {
  height: 40px;
  border: 1px solid #bbb;
  display: inline-block;
  float: left;
  border-radius: 3px;
}

.login-input .MuiInput-input {
  height: 40px;
  width: 100%;
  margin: 0;
  padding: 6px 8px;
  line-height: 16px;
  border: none;
  background: transparent;
  box-sizing: border-box;
  font-size: 13px;
  font-family: "OpenSans";
}

.login-page {
  margin-top: 8rem;
}

.align-middle {
  display: flex;
  place-content: center;
}

.toast-body {
  font-size: 1.25rem;
}

.captcha-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  /* Set a high z-index value */
  display: none;
}

.captcha-modal {
  /* Additional styling for the modal content */
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.right-btn-group {
  display: flex;
  gap: 12px;
}