.trial-form-label {
  font-size: 1.4rem;
  line-height: 2.4rem;
  color: #000;
  font-family: 'OpenSans';
  margin-bottom: 0.8rem;
  margin-top: 0.8rem;
}

.trial-form-label.lg {
  font-size: 1.6rem;
  font-family: 'OpenSansSemiBold';
  line-height: 2rem;
}
