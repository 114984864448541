.text-lg {
  color: green;
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.text-sm {
  font-size: 1.5rem;
}

.loading-dots {
  position: inherit;
  margin-left:12rem;
  margin-bottom: 1rem;
}
