@import "./colors.css";

.page-container {
  height: 100%;
  max-width: 100vw;
  min-height: 100vh;
}

.center-page {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.green-text {
  color: var(--textGreenLight);
}

.error-text {
  color: var(--errorPink);;
  font-size: smaller;
}


